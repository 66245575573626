
function getLineData(){
    let data = [
        {value: 0, label: 0},
        {value: 9, label: 1},
        {value: 8, label: 2},
        {value: 6, label: 3},
        {value: 2.8, label: 4},
        {value: 1.7, label: 5},
        {value: 0.7, label: 6}
    ]
    return data
}

export default getLineData