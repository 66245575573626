import React, {useState, useEffect} from 'react'
import LibraryWrapper from './LibraryWrapper'
import { DateTime } from 'luxon'
import { Button, ReportChart, ReportGlanc, ReportLineChart, ScoreScale } from './../../components/library'
import getColorFromLevel from '../../utils/getColorFromLevel'
import getRandomInt from '../../utils/getRandomInt'
import getRandomData from '../../utils/getRandomData'
import getLineData from '../../utils/getLineData'




function reportChart() {

    const randomData =  {
        sample1: [],
        sample2: getRandomData(),
        sample3: getRandomData('integer', 0, 28),
        sample4: getRandomData('integer'),
        sample5: getRandomData('percent', 0, 28, 2),
        sample6: getLineData(),
    }
    
    return (
        <LibraryWrapper>
            <ReportChart
                id="sample1"
                icon="spirometer"
                icon_color="pink"
                data={randomData.sample1}
                unit="percent"
                to="/components"
                max={1}
            />
            <hr />
            <ReportChart
                id="sample2"
                icon="spirometer"
                icon_color="pink"
                data={randomData.sample2}
                unit="percent"
                to="/components"
                max={1}
            />
            <hr />
            <ReportChart
                id="sample3"
                title="C-ACT"
                help="This is a tooltip text"
                icon="clipboard"
                icon_color="blue"
                data={randomData.sample3}
                levels={[
                    {from: 0, to: 7, level: 0, label: 'Bad'},
                    {from: 7, to: 21, level: 1, label: 'OK'},
                    {from: 21, to: 999, level: 4, label: 'Good'},
                ]}
                to="/components"
                unit="integer"
                hideEmptyDays
            />
            <hr />
            <ReportChart
                id="sample4"
                icon="lungs"
                data={randomData.sample4}
                to="/components"
                unit="integer"
                glanceDescription={{singular: 'Symptom', plural: 'Symptoms'}}
                disableLevelColors
                
            />
            <hr />
            <ReportChart
                id="sample5"
                data={randomData.sample5}
                unit="percent"
                max={1}
                defaultMode="week"
                hideEmptyDays
                hideTopbar
                chartType="BarChart"
                disableInteractivity
            />
            <hr />
            <ReportLineChart
                id="sample6"
                data={randomData.sample6}
                yLabel="Flow (L/s)"
                xLabel="Volume (L)"
            />
            <hr />
            <ScoreScale
                levels={[
                    {value: 80, label: '0-80% poorly controlled', level: 0},
                    {value: 160, label: '>=80% well controlled', level: 4},
                ]}
                hideLastNum
            />
            <hr />
            <ScoreScale
                levels={[
                    {value: 13, label: '0-13 poorly controlled', level: 0},
                    {value: 20, label: '14-20 partially controlled', level: 1},
                    {value: 27, label: '21-27 well controlled', level: 4},
                ]}
            />
        </LibraryWrapper>
    )
}

export default reportChart
