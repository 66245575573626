import { DateTime } from 'luxon'
import getRandomInt from './getRandomInt'

function getRandomData(unit = 'percent', min = 0, max = 8, num_days){
    let data = []
    num_days = num_days || getRandomInt(1,30)
    let count = 0
    for (let index = 0; index < num_days; index++) {
        count = !index ? count : count - getRandomInt(1, 3)
        const past = !index ? count : count
        const iso = DateTime.now().plus({days: count}).toISO()
        const date = DateTime.fromISO(iso)
        let value = unit === 'percent' ? Math.random() : getRandomInt(min, max);
        if(!count && getRandomInt(0,1)) value = 0
        data[index] = ({value, date, ts: (date.ts / 1000)})
    }
    return data
}

export default getRandomData